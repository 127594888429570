.App {
  text-align: center;
  background-repeat: repeat-xy;
  background-image: url('https://d3d00swyhr67nd.cloudfront.net/w1200h1200/collection/EDII/RSA/EDII_RSA_2000_078-001.jpg');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes slide_text {
  0% {
    transform: translateX( 0 )
  }
  100% {
    transform: translateX( 400 )
  }
}

.info {
  position: absolute;
  top: 300px;
  left: 100px;
  padding: 10px;
  border-radius: 10px;
  background: green;
  color: red;
  animation: slide_text 4s ease forwards;
  transform: translate(0, 0);
  opacity: 1;
  filter: blur(0);
}

@keyframes slide_text {
  0% {
    transform: translate(0, 0);
  }
  60% {
    transform: translate(15vw, 0vw) scale(1.6);
  }
  80% {
      transform: translate(15vw, 0vw) scale(1.6);
    filter: blur(0);
    opacity: 1;
  }
  90% {

      transform: translate(15vw, 0vw) scale(1.6);
    opacity: 0.9;
  }
  100% {

      transform: translate(15vw, 0vw) scale(2.4);
    filter: blur(50px);
    opacity: 0;
  }
}

@keyframes slide {
  0% {
    transform: translateY( 0% );
  }
  100% {
    transform: translateY( -10% );
  }
}
@keyframes sliderev {
  0% {
    transform: translateY( 0% );
  }
  100% {
    transform: translateY( 9.0909% );
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.hand {
  width:"500px";
  height: "400px";
}

.play {
  color: yellow;
  background-color: transparent;
  z-index: 1;
  height: 800px;
  width: 900px;
}

.playerHand {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  width: 500px;
  height: 300px;
}

.deck {
  position: absolute;
  top: 270px;
  left: 0px;

}

.showCard {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.showCard span {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
  opacity: 50%;
}

.cardHolder {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 100%;
  z-index: 3;
}

.showCard img {
  height: 600px;
  opacity: 100%;
  z-index: 12;
}

.playerScore {
  position: absolute;
  background-color: transparent;
  color: yellow;
  top: 130px;
  left: 20px;
  font-size: 80px;
}
.opponentScore {
  position: absolute;
  color: yellow;
  top: 130px;
  left: 20px;
  font-size: 80px;
}
.peggingHandTotal {
  position: absolute;
  top: 100px;
  font-size: 80px;
}

.opponentHand {
  background-color: transparent;
  position: absolute;
  top: 480px;
  left: 0px;
  width: 500px;
  height: 300px;
}

.cribHand {
  position: absolute;
  top: 100px;
  left: 20px;
}

.opponentCribHand {
  position: absolute;
  top: 530px;
  left: 20px;
}
.board {
  position: absolute;
  top: 80px;
  left: 850px;
}

.commitCrib {
  position: absolute;
  top: 20px;
  left: 50px;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
